@import "../variables";

:root {
  .MuiDrawer-root .MuiDrawer-paper {
    background-color: $primary;
    z-index: 1201;
    border: none;
  }
}

.sidebarContainer {
  border-left: 10px solid $primary-color;

  .active {
    background-color: $background-color;

    .sidebarText {
      font-weight: 700;
      color: $text-color;
      background-color: transparent;

      .sidebarIcon {
        min-width: 35px;
        max-width: 35px;
        width: 35px;
      }

      .sidebarIcon img {
        width: 20px;
        height: 20px;
        filter: brightness(50%) hue-rotate(87deg);
      }

      img::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #235d5e;
        opacity: 0.5;
      }

      :hover {
        background-color: transparent;
      }
    }

    :hover {
      background-color: transparent;
    }

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    ::before {
      content: "";
      position: absolute;
      top: -70px;
      right: 0px;
      width: 50px;
      height: 70px;
      background-color: $primary-color;
      box-shadow: 15px 15px 0 white;
      border-bottom-right-radius: 38px;
      z-index: -999;
    }

    ::after {
      content: "";
      position: absolute;
      bottom: -70px;
      right: 0;
      width: 50px;
      height: 70px;
      background-color: $primary-color;
      border-top-right-radius: 38px;
      box-shadow: 15px -15px 0 white;
      z-index: -999;
    }
  }

  .sidebarText {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: $background-color;
    background-color: transparent;

    :hover {
      background-color: transparent;
    }

    .sidebarIcon {
      min-width: 35px;
      max-width: 35px;
      width: 35px;
    }

    .sidebarIcon img {
      width: 20px;
      height: 20px;
    }
  }
}