.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.whyUsCardMain{
    display: flex;
    flex-wrap: wrap;
    padding: 50px 25px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 899px) {
        padding: 30px 25px;
        gap: 20px;
    }
    @media screen and (max-width: 599px) {
        flex-direction: column;
        gap: 20px;
    }
    .imgSec{
        img{
            max-width: 100%;
        }
    }
    .order1{
        @media screen and (max-width: 599px)  {
            order: 1;
         }
      
    }
    .order2{
        @media screen and (max-width: 599px)  {
            order: 2;
         }
    }
    .whyUsCard{
        flex-basis: calc(50% - 22px);
        text-align: left;
        position: relative;
        @media screen and (max-width: 899px)  {
            flex-basis: calc(50% - 11px);
            // flex-basis: calc(100%);
         }
         @media screen and (max-width: 599px)  {
            flex-basis: calc(100%);
         }
       .name{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
            text-transform: uppercase;
            @media screen and (max-width: 899px)  {
                font-size: 20px;
                margin-bottom: 0px;
            }
            @media screen and (max-width: 599px)  {
                font-size: 18px;
            }
       }
       .title{
            font-size: 18px;
            @media screen and (max-width: 899px)  {
                font-size: 18px;
            }
            @media screen and (max-width: 599px)  {
                font-size: 16px;
            }
        }
       .heading{
            font-size: 60px;
            font-weight: 600;
            margin-bottom: 20px;
            @media screen and (max-width: 899px)  {
                font-size: 42px;
                line-height: 45px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            @media screen and (max-width: 599px)  {
                font-size: 36px;
            }
        }
       .para{
            font-size: 16px;
            @media screen and (max-width: 899px)  {
                font-size: 13px;
            }
        }
    }
}
.serviceMain{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0 20px 50px;
    @media screen and (max-width: 899px)  {
        gap: 15px;
    }
    .serviceCard{
        flex-basis: calc(33% - 25px);
        text-align: center;
        @media screen and (max-width: 899px)  {
            flex-basis: calc(33% - 9px);
        }
        @media screen and (max-width: 599px)  {
            flex-basis: calc(100%);
        }
        .heading{
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 20px;
            @media screen and (max-width: 899px)  {
                font-size: 20px;
            }
            @media screen and (max-width: 599px)  {
                font-size: 14px;
            }
        }
        
        .para{
            font-size: 15px;
            max-width: 250px;
            margin: 0 auto;
            @media screen and (max-width: 899px)  {
                font-size: 13px;
                max-width: 200px;
            }
            @media screen and (max-width: 599px)  {
                font-size: 14px;
                max-width: 100%;
            }
        }
        
    }
}