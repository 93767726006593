@import "../../../../scss/variables";
@import "../../../../scss/mixins/mixins";

.main-padding-secondary {
  padding: 20px;
}

.newsletter {
  padding: 3rem 0rem 2rem;
  background-color: $primary;
  background-image: url("../../../assets/images/bg-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 100%;

  .newsletter-image {
    background-image: url("../../../assets/images/newsletter.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    @media only screen and (max-width: 600px) {
      background-size: 100%;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: -100px;
      left: 22px;
      bottom: auto;
      right: 0;
    }
  }

  .newsletter-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .newsletter-heading {
    line-height: 1.3;
    font-size: 40px;
    font-weight: 700;
    color: $bgColor;
    @media only screen and (max-width: 600px) {
      margin: 10px 0;
      padding: 0 15px;
    }
  }
}

.footer {
  width: 100%;
  bottom: 0;
  position: fixed;

  .css-16lloyr-MuiBottomNavigation-root {
    height: 30px;
    align-items: center;

    @include themify($themes) {
      background-color: themed("barBg");
      color: themed("txtColor");
    }
  }
}

.nav-heading {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $ebony-color !important;
}

.footer-sub-heading {
  color: $ebony-color !important;
}

.footer-line {
  border: 1px solid $grey;
}

@media (min-width: 900px) and (max-width: 1200px) {
  .newsletter {
    .newsletter-heading {
      font-size: 32px;
    }
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .newsletter {
    .newsletter-heading {
      font-size: 28px;
    }
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .newsletter {
    padding: 1rem 0rem 3rem 0rem;

    .newsletter-heading {
      font-size: 20px;
    }
  }
}

@media (min-width: 0px) and (max-width: 330px) {
  .main-padding-secondary {
    padding: 10px;
  }
}
