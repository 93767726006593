@import "../../../../scss/variables";

.custom-pagination-item-selected {
  .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
    background: #235d5e;
    border: 1px solid $alice;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #ffff;
  }

  li {
    &:last-child {
      .MuiPaginationItem-previousNext {
        background-color: $primary;
        border-radius: 10px;
        color: #ffff;
      }
    }
  }
}
