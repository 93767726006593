.logoContainer {
  display: flex;

  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}

.logoContainerSignUp {
  display: flex;
  // margin-top: 24px;
  // width: 80%;
  // margin-left: auto;

  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}

.main {
  width: auto;
  height: auto;
  border-radius: 20px;
  padding: 10px;
}

@media (max-width: 900px) {
  .main {
    display: none;
  }
}
.loginImg {
  position: relative;
  img {
    max-width: 100%;
  }
  .inner {
    position: absolute;
    bottom: 40px;
    width: 100%;
    padding: 0 50px;
    text-align: center;
    color: white;
    @media (max-width: 900px) {
      padding: 0;
    }
    .head {
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 20px;
      @media (max-width: 1199px) {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 22px;
      }
      @media (max-width: 899px) {
        font-size: 18px;
      }
    }
    .text {
      font-size: 13px;
      @media (max-width: 1199px) {
        font-size: 10px;
      }
      @media (max-width: 899px) {
        font-size: 10px;
      }
    }
  }
}
