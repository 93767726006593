@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "variables";
@import "mixins/mixins";
@import "components/card";
@import "components/input";
@import "components/modal";
@import "components/buttons";
@import "components/table";
@import "components/sidebar";
@import "components/accordian";
@import "components/stepper";
@import "../../src/app/assets/font/stylesheet.css";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rowText {
  white-space: nowrap;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

* {
  font-family: "Marlin Geo" !important;
  @extend .style-1;
}

.pac-container {
  z-index: 9999;
}

.text-ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  background-size: 30px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}

:root {
  .MuiTypography-h6 {
    color: $primary;
  }

  .MuiDivider-root {
    border-color: $primary;
    opacity: 1;
  }
}

.img-loader.CircularProgressbar {
  width: 40%;
}

.profileImgLoader.CircularProgressbar {
  width: 90%;
}

.p-absolute {
  position: absolute;
  left: 5px;
  top: 5px;
}

.style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.style-1::-webkit-scrollbar {
  width: 10px;
}

.style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-color;
}

.style-1::-webkit-scrollbar:vertical {
  width: 10px;
  height: 10px;
}

.style-1::-webkit-scrollbar:horizontal {
  width: 12px;
  height: 12px;
}

.filter-menu {
  border-bottom: 1px solid #ccc !important;
  padding: 8px !important;
}

.dialog-title {
  padding: 16px 24px 0px 24px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #101828 !important;
}

.product-card.MuiCard-root {
  border-radius: 0px;
  padding: 20px;
  border: 1px solid #efefef;
  box-shadow: none;
  cursor: pointer;
}

.auth-main-heading {
  font-weight: 700 !important;
  font-size: 48px !important;
  color: #000000 !important;
}

.auth-signup-heading {
  font-weight: 700 !important;
  font-size: 38px !important;
  color: #000000 !important;
}

@media (max-width: 465px) {
  .auth-main-heading {
    font-weight: 700 !important;
    font-size: 36px !important;
  }

  .auth-signup-heading {
    font-weight: 700 !important;
    font-size: 32px !important;
  }
}

@media (max-width: 380px) {
  .auth-container {
    padding: 10px;
  }

  .auth-signup-heading {
    font-weight: 700 !important;
    font-size: 26px !important;
  }
}
