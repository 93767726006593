@font-face {
    font-family: 'Marlin Geo ExtraBlack';
    src: url('MarlinGeo-ExtraBlack.eot');
    src: local('Marlin Geo ExtraBlack'), local('MarlinGeo-ExtraBlack'),
        url('MarlinGeo-ExtraBlack.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraBlack.woff2') format('woff2'),
        url('MarlinGeo-ExtraBlack.woff') format('woff'),
        url('MarlinGeo-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Black.eot');
    src: local('Marlin Geo Black'), local('MarlinGeo-Black'),
        url('MarlinGeo-Black.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Black.woff2') format('woff2'),
        url('MarlinGeo-Black.woff') format('woff'),
        url('MarlinGeo-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Bold.eot');
    src: local('Marlin Geo Bold'), local('MarlinGeo-Bold'),
        url('MarlinGeo-Bold.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Bold.woff2') format('woff2'),
        url('MarlinGeo-Bold.woff') format('woff'),
        url('MarlinGeo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-BoldItalic.eot');
    src: local('Marlin Geo Bold Italic'), local('MarlinGeo-BoldItalic'),
        url('MarlinGeo-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-BoldItalic.woff2') format('woff2'),
        url('MarlinGeo-BoldItalic.woff') format('woff'),
        url('MarlinGeo-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-BlackItalic.eot');
    src: local('Marlin Geo Black Italic'), local('MarlinGeo-BlackItalic'),
        url('MarlinGeo-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-BlackItalic.woff2') format('woff2'),
        url('MarlinGeo-BlackItalic.woff') format('woff'),
        url('MarlinGeo-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-ExtraBold.eot');
    src: local('Marlin Geo ExtraBold'), local('MarlinGeo-ExtraBold'),
        url('MarlinGeo-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraBold.woff2') format('woff2'),
        url('MarlinGeo-ExtraBold.woff') format('woff'),
        url('MarlinGeo-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo ExtraBlack';
    src: url('MarlinGeo-ExtraBlackItalic.eot');
    src: local('Marlin Geo ExtraBlack Italic'), local('MarlinGeo-ExtraBlackItalic'),
        url('MarlinGeo-ExtraBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraBlackItalic.woff2') format('woff2'),
        url('MarlinGeo-ExtraBlackItalic.woff') format('woff'),
        url('MarlinGeo-ExtraBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-ExtraBoldItalic.eot');
    src: local('Marlin Geo ExtraBold Italic'), local('MarlinGeo-ExtraBoldItalic'),
        url('MarlinGeo-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraBoldItalic.woff2') format('woff2'),
        url('MarlinGeo-ExtraBoldItalic.woff') format('woff'),
        url('MarlinGeo-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Bold.eot');
    src: local('Marlin Geo Slant Bold'), local('MarlinGeoSlant-Bold'),
        url('MarlinGeoSlant-Bold.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Bold.woff2') format('woff2'),
        url('MarlinGeoSlant-Bold.woff') format('woff'),
        url('MarlinGeoSlant-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Thin.eot');
    src: local('Marlin Geo Slant Thin'), local('MarlinGeoSlant-Thin'),
        url('MarlinGeoSlant-Thin.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Thin.woff2') format('woff2'),
        url('MarlinGeoSlant-Thin.woff') format('woff'),
        url('MarlinGeoSlant-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-ExtraLight.eot');
    src: local('Marlin Geo ExtraLight'), local('MarlinGeo-ExtraLight'),
        url('MarlinGeo-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraLight.woff2') format('woff2'),
        url('MarlinGeo-ExtraLight.woff') format('woff'),
        url('MarlinGeo-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-LightItalic.eot');
    src: local('Marlin Geo Light Italic'), local('MarlinGeo-LightItalic'),
        url('MarlinGeo-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-LightItalic.woff2') format('woff2'),
        url('MarlinGeo-LightItalic.woff') format('woff'),
        url('MarlinGeo-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo Slant SemiLight';
    src: url('MarlinGeoSlant-SemiLight.eot');
    src: local('Marlin Geo Slant SemiLight'), local('MarlinGeoSlant-SemiLight'),
        url('MarlinGeoSlant-SemiLight.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-SemiLight.woff2') format('woff2'),
        url('MarlinGeoSlant-SemiLight.woff') format('woff'),
        url('MarlinGeoSlant-SemiLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Thin.eot');
    src: local('Marlin Geo Thin'), local('MarlinGeo-Thin'),
        url('MarlinGeo-Thin.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Thin.woff2') format('woff2'),
        url('MarlinGeo-Thin.woff') format('woff'),
        url('MarlinGeo-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Light.eot');
    src: local('Marlin Geo Slant Light'), local('MarlinGeoSlant-Light'),
        url('MarlinGeoSlant-Light.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Light.woff2') format('woff2'),
        url('MarlinGeoSlant-Light.woff') format('woff'),
        url('MarlinGeoSlant-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-ExtraBold.eot');
    src: local('Marlin Geo Slant ExtraBold'), local('MarlinGeoSlant-ExtraBold'),
        url('MarlinGeoSlant-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-ExtraBold.woff2') format('woff2'),
        url('MarlinGeoSlant-ExtraBold.woff') format('woff'),
        url('MarlinGeoSlant-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-ExtraLightItalic.eot');
    src: local('Marlin Geo ExtraLight Italic'), local('MarlinGeo-ExtraLightItalic'),
        url('MarlinGeo-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ExtraLightItalic.woff2') format('woff2'),
        url('MarlinGeo-ExtraLightItalic.woff') format('woff'),
        url('MarlinGeo-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Regular.eot');
    src: local('Marlin Geo Regular'), local('MarlinGeo-Regular'),
        url('MarlinGeo-Regular.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Regular.woff2') format('woff2'),
        url('MarlinGeo-Regular.woff') format('woff'),
        url('MarlinGeo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo SemiLight';
    src: url('MarlinGeo-SemiLightItalic.eot');
    src: local('Marlin Geo SemiLight Italic'), local('MarlinGeo-SemiLightItalic'),
        url('MarlinGeo-SemiLightItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-SemiLightItalic.woff2') format('woff2'),
        url('MarlinGeo-SemiLightItalic.woff') format('woff'),
        url('MarlinGeo-SemiLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-ExtraLight.eot');
    src: local('Marlin Geo Slant ExtraLight'), local('MarlinGeoSlant-ExtraLight'),
        url('MarlinGeoSlant-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-ExtraLight.woff2') format('woff2'),
        url('MarlinGeoSlant-ExtraLight.woff') format('woff'),
        url('MarlinGeoSlant-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Medium.eot');
    src: local('Marlin Geo Slant Medium'), local('MarlinGeoSlant-Medium'),
        url('MarlinGeoSlant-Medium.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Medium.woff2') format('woff2'),
        url('MarlinGeoSlant-Medium.woff') format('woff'),
        url('MarlinGeoSlant-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-ThinItalic.eot');
    src: local('Marlin Geo Thin Italic'), local('MarlinGeo-ThinItalic'),
        url('MarlinGeo-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-ThinItalic.woff2') format('woff2'),
        url('MarlinGeo-ThinItalic.woff') format('woff'),
        url('MarlinGeo-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Black.eot');
    src: local('Marlin Geo Slant Black'), local('MarlinGeoSlant-Black'),
        url('MarlinGeoSlant-Black.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Black.woff2') format('woff2'),
        url('MarlinGeoSlant-Black.woff') format('woff'),
        url('MarlinGeoSlant-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Italic.eot');
    src: local('Marlin Geo Italic'), local('MarlinGeo-Italic'),
        url('MarlinGeo-Italic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Italic.woff2') format('woff2'),
        url('MarlinGeo-Italic.woff') format('woff'),
        url('MarlinGeo-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Medium.eot');
    src: local('Marlin Geo Medium'), local('MarlinGeo-Medium'),
        url('MarlinGeo-Medium.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Medium.woff2') format('woff2'),
        url('MarlinGeo-Medium.woff') format('woff'),
        url('MarlinGeo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-MediumItalic.eot');
    src: local('Marlin Geo Medium Italic'), local('MarlinGeo-MediumItalic'),
        url('MarlinGeo-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-MediumItalic.woff2') format('woff2'),
        url('MarlinGeo-MediumItalic.woff') format('woff'),
        url('MarlinGeo-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Marlin Geo Slant ExtraBlack';
    src: url('MarlinGeoSlant-ExtraBlack.eot');
    src: local('Marlin Geo Slant ExtraBlack'), local('MarlinGeoSlant-ExtraBlack'),
        url('MarlinGeoSlant-ExtraBlack.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-ExtraBlack.woff2') format('woff2'),
        url('MarlinGeoSlant-ExtraBlack.woff') format('woff'),
        url('MarlinGeoSlant-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo';
    src: url('MarlinGeo-Light.eot');
    src: local('Marlin Geo Light'), local('MarlinGeo-Light'),
        url('MarlinGeo-Light.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-Light.woff2') format('woff2'),
        url('MarlinGeo-Light.woff') format('woff'),
        url('MarlinGeo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo SemiLight';
    src: url('MarlinGeo-SemiLight.eot');
    src: local('Marlin Geo SemiLight'), local('MarlinGeo-SemiLight'),
        url('MarlinGeo-SemiLight.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeo-SemiLight.woff2') format('woff2'),
        url('MarlinGeo-SemiLight.woff') format('woff'),
        url('MarlinGeo-SemiLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Marlin Geo Slant';
    src: url('MarlinGeoSlant-Regular.eot');
    src: local('Marlin Geo Slant Regular'), local('MarlinGeoSlant-Regular'),
        url('MarlinGeoSlant-Regular.eot?#iefix') format('embedded-opentype'),
        url('MarlinGeoSlant-Regular.woff2') format('woff2'),
        url('MarlinGeoSlant-Regular.woff') format('woff'),
        url('MarlinGeoSlant-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

