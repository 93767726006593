.cart-text {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #101828;
}

.cart-price {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  color: #16151a;
}

.summary-card.MuiCard-root {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 84px 214px -80px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 0px !important;
}

.summary-heading {
  font-style: normal !important;
  font-weight: 500 !important;
  color: #101828;
  white-space: nowrap;
}

.summary-cal {
  font-weight: 400 !important;
  color: #101828;
  font-style: normal !important;
  white-space: normal;
}

.btn-full-length {
  color: #ffffff !important;
  background-color: #235d5e !important;
  text-transform: capitalize !important;
  margin-top: 10px !important;
}