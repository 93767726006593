.cartCounter {
  .MuiOutlinedInput-root {
    padding-right: 6px !important;
  }

  .MuiOutlinedInput-input {
    padding: 16.5px 0px !important;
    padding-right: 12px !important;
  }
}
