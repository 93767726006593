html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #2e2e2e;
  // -ms-touch-action: none;
  // touch-action: none;
  // -ms-content-zooming: none;
}

#root {
  padding: 0;
  height: 100%;
}

.outer-wrapper {
  padding: 0;
  height: 100%;
}

.language-toggle {
  .MuiSwitch-root {
    width: 44px;
    height: 23px;
    padding: 0;
    background-color: #235d5e;
    border-radius: 14px;
  }

  .MuiSwitch-switchBase {
    padding: 1px;
    color: #fff;
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }

  .MuiSwitch-track {
    background-color: #235d5e;
  }

  &.active {
    .MuiSwitch-switchBase {
      color: #fff;
    }

    .MuiSwitch-track {
      background-color: #fff;
    }
  }

  .MuiFormControlLabel-label {
    font-weight: 600;
  }
}

.contactUs-language-toggle {
  .MuiSwitch-root {
    width: 44px;
    height: 23px;
    padding: 0;
    background-color: #a19f9f;
    border-radius: 14px;
  }

  .MuiSwitch-switchBase {
    padding: 1px;
    color: #fff;
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }

  .MuiSwitch-track {
    background-color: #a19f9f;
  }

  &.active {
    .MuiSwitch-switchBase {
      color: #fff;
    }

    .MuiSwitch-track {
      background-color: #fff;
    }
  }

  .MuiFormControlLabel-label {
    font-weight: 600;
  }
}
