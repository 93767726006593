.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.blogCardMain{
    display: flex;
    margin: 80px 0 60px;
    flex-wrap: wrap;
    gap: 20px;
    @media screen and (max-width: 599px) {
        flex-direction: column;
        margin:30px 0 10px;
    }
    .blogCard{
        flex-basis: calc(33% - 11px);
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 0 0 10px -2px #235d5e;
        text-align: center;
        overflow: hidden;
        @media screen and (max-width: 899px)  {
            flex-basis: calc(50% - 10px);
         }
         @media screen and (max-width: 599px)  {
            flex-basis: calc(100%);
         }
       
        .imageIcon{
            img{
                max-width: 100%;
            }
        }
        .blogText{
            padding: 15px 15px;
            text-align: left;
            .heading{
                font-size: 22px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 15px;
                color: #235d5e;
                height: 58px;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 899px)  {
                    font-size: 18px;
                    height: 55px;
                }
                @media screen and (max-width: 599px)  {
                    height: auto;
                    max-height: 90px;
                }
            }
            .title{
                font-size: 14px;
                margin: 0 auto 10px;
                height: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 899px)  {
                    font-size: 14px;
                    line-height: 18px;
                    height: 110px;
                }
                @media screen and (max-width: 599px)  {
                    font-size: 14px;
                    line-height: 18px;
                    height: auto;
                    max-height: 110px;
                }
            }
            
            .cardBtn{
                text-align: right;
                button{
                    border-radius: 5px;
                    background-color: #235d5e !important;
                    color: white;
                    padding: 5px 15px;
                    font-size: 12px !important;
                    @media screen and (max-width: 899px)  {
                        font-size: 12px !important;
                    }
                }
            }
        }
        
    }
}
.blogDetailCardMain{
    .blogDetailCard{
        .imageIcon{
            img{
                width: 100%;
                margin-bottom: 25px;
            }
        }
        .blogText{
            .heading{
                font-size: 22px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 15px;
                color: #235d5e;
                @media screen and (max-width: 899px)  {
                    font-size: 18px;
                }
            }
            .title{
                font-size: 15px;
                margin: 0 auto 10px;
                @media screen and (max-width: 899px)  {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.blogList{
    margin-left: 25px;
    border-left: 1px solid #e5e5e5;
    position: sticky;
    top: 25px;
    @media screen and (max-width: 899px)  {
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
    }
    @media screen and (max-width: 599px)  {
        margin-left: 0;
    border-left: 0 solid #e5e5e5;
    }
    .listHeading{
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 15px;
        color: #235d5e;
        padding: 0 15px;
        @media screen and (max-width: 899px)  {
            font-size: 15px;
        }
        @media screen and (max-width: 599px)  {
            font-size: 18px;
        }
    }
    ul{
        padding: 0;
        list-style: none;
        li{
            .listText{
                cursor: pointer;
                padding: 10px 20px;
                font-size: 14px;
                line-height: 18px;
                border-bottom: 1px solid #e5e5e5;
                @media screen and (max-width: 899px)  {
                    padding: 10px 15px;
                    font-size: 11px;
                    line-height: 13px;
                }
                @media screen and (max-width: 599px)  {
                    padding: 10px 15px;
                    font-size: 14px;
                    line-height: 17px;
                }
              
                &:hover{
                    background-color: #e5e5e5;
                    color: #235d5e;
                }
            }
        }
    }
}