.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.approachCardMains{
  .approachImg{
    @media screen and (max-width: 899px) {
      height: 100%;
    }
    img{
      max-width: 100%;
      @media screen and (max-width: 899px) {
        height: 100%;
        object-fit: cover;
      }
      @media screen and (max-width: 599px) {
        margin-bottom: 20px;
        height: auto;
      }
    }
  }
  .approachText{
    display: flex;
    margin-bottom: 40px;
    padding-left: 30px;
    gap: 20px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 899px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 599px) {
      padding-left: 0;
    }
    .icons{
      img{
        width: 40px;
      }
    }
    .head{
      .h3{
        font-size: 20px;
        font-weight: 600;
      }
      .p{
        font-size: 15px;
        @media screen and (max-width: 1199px) {
          font-size: 13px;
        }
        @media screen and (max-width: 899px) {
          font-size: 12px;
        }
      }
    }
  }
}