.landingPageheader {
  display: flex;
  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}

.landingPageheader1 {
  display: none;
  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}

@media (min-width: 1200px) {
  .landingPageheader {
    display: none;
  }
  .landingPageheader1 {
    display: flex;
  }
}

.uniorxLandingPageContainer {
  margin: 0px;
  padding: 0px !important;
  .mainHeading {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    margin: auto;
    line-height: 1.2;
    padding: 16px;
  }
  .mainParagraph {
    font-size: 14px;
    text-align: center;
    margin: auto;
  }

  .dashboardImage {
    width: 100%;
    height: 100%;
    padding: 0rem 6rem;
  }

  .secondSection {
    .userDashboard {
      border: 1px solid #efefef;
      border-bottom: none;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      width: 60%;
      margin: auto;
      padding: 20px 30px;
    }
    .seconCardDetail {
      border: 1px solid #fefe;
      border-radius: 12px;
      padding: 3rem;
      position: relative;
      background: #ffffff;
      @media only screen and (max-width: 900px) {
        padding: 1rem;
      }
      .B2B-DetailHeading {
        font-size: 36px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 16px;
      }
      .B2B-Detail {
        font-size: 20px;
        line-height: 1.2;
      }
      .landingPageBackground {
        background-image: url("../../assets/images/landingPageImage.svg");
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;
        min-height: 100%;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 4rem;
        top: 0;
      }
    }
  }
  .custom-pos {
    @media only screen and (max-width: 600px) {
      background-size: 340px !important;
      margin-right: -135px !important;
    }
  }
  .thirdSection {
    background: #235d5e;
    padding: 40px 0px;
    @media only screen and (max-width: 600px) {
      margin-left: -16px;
      margin-right: -16px;
      overflow: hidden;
    }
    .cardInfo {
      border: 1px solid #1d5455;
      background-image: url("../../assets/images/bg-banner.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
      padding: 60px 30px;
      position: relative;
      box-shadow: 0 1px 15px 2px rgba(29, 84, 85, 86);
      .cardHeading {
        font-size: 22px;
        font-weight: 500;
        margin: auto;
        line-height: 1.2;
        margin-bottom: 15px;
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      .cardDetail {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.2;
        @media only screen and (max-width: 600px) {
          font-size: 12px;
          br {
            display: none;
          }
        }
      }
      .thirdCardBackground {
        background-image: url("../../assets/images/landingPageImage1.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;
        min-height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 100px;
        top: 0;
        @media only screen and (max-width: 600px) {
          background-position: right;
          background-size: 230px;
          margin-right: -30px;
          width: auto;
          position: relative;
          bottom: auto;
          right: auto;
          left: auto;
          top: auto;
        }
      }
      .forthCardBackground {
        background-image: url("../../assets/images/landingPageImage4.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;
        min-height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 80px;
        top: 0;
        @media only screen and (max-width: 600px) {
          background-position: right;
          background-size: 230px;
          margin-right: -30px;
          width: auto;
          position: relative;
          bottom: auto;
          right: auto;
          left: auto;
          top: auto;
        }
      }
    }

    .secondCardInfo {
      border: 1px solid #1d5455;
      background-image: url("../../assets/images/bg-banner.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
      padding: 4rem 2rem;
      position: relative;
      box-shadow: 0 1px 15px 2px rgba(29, 84, 85, 86);
      .cardHeading {
        font-size: 22px;
        font-weight: 500;
        margin: auto;
        line-height: 1.2;
        margin-bottom: 15px;
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      .cardDetail {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.2;
        width: 80%;
        @media only screen and (max-width: 600px) {
          font-size: 12px;
          br {
            display: none;
          }
        }
      }
      .thirdCardBackground {
        background-image: url("../../assets/images/landingPageImage2.svg");
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 95%;
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        @media only screen and (max-width: 600px) {
          background-position: right;
          background-size: 230px;
          margin-right: -30px;
          width: auto;
          position: relative;
          bottom: auto;
          right: auto;
          left: auto;
          top: auto;
        }
      }
    }
  }

  .forthSection {
    .forthSectionHeading {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin: auto;
      line-height: 1.2;
      padding: 16px;
    }

    .forthSectionDetail {
      .forthSectionDetailHeading {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 2rem;
      }
    }

    .forthCardBackground {
      background-image: url("../../assets/images/landingPageImage3.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: cover;
      width: 60%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0px;
      top: 0;
      margin-left: auto;
      @media only screen and (max-width: 600px) {
        background-position: right;
        background-size: 230px;
        margin-right: -30px;
        width: auto;
        position: relative;
        bottom: auto;
        right: auto;
        left: auto;
        top: auto;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1536px) {
  .uniorxLandingPageContainer {
    .mainHeading {
      font-size: 36px;
    }
    .mainParagraph {
      font-size: 16px;
    }

    .secondSection {
      .seconCardDetail {
        .B2B-DetailHeading {
          font-size: 32px;
        }
        .B2B-Detail {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .uniorxLandingPageContainer {
    .mainHeading {
      font-size: 32px;
    }

    .secondSection {
      .seconCardDetail {
        .B2B-DetailHeading {
          font-size: 28px;
        }
        .B2B-Detail {
          font-size: 16px;
        }
      }
    }

    .forthSection {
      .forthSectionHeading {
        font-size: 38px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .uniorxLandingPageContainer {
    .mainHeading {
      font-size: 30px;
    }

    .secondSection {
      .seconCardDetail {
        .B2B-DetailHeading {
          font-size: 24px;
        }
        .B2B-Detail {
          font-size: 16px;
        }
      }
    }

    .thirdSection {
      .cardInfo {
        padding: 2rem;
      }
      .secondCardInfo {
        padding: 2rem;
      }
    }

    .forthSection {
      .forthSectionHeading {
        font-size: 32px;
      }
    }
  }
}
.cusTab {
  > div {
    > div {
      gap: 5px;
      > button {
        min-width: 75px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .landingPageheader {
    .logoText {
      font-size: 21.7758px;
    }
  }

  .landingPageheader1 {
    .logoText {
      font-size: 21.7758px;
    }
  }
  .mainParagraph {
    font-size: 13px;
    br {
      display: none;
    }
  }
  .uniorxLandingPageContainer {
    .mainHeading {
      font-size: 20px;
    }

    .dashboardImage {
      padding: 2rem;
    }

    .secondSection {
      .userDashboard {
        width: 96%;
        padding: 20px 5px;
      }
      .seconCardDetail {
        padding: 2rem;
        .B2B-DetailHeading {
          font-size: 19px;
        }
        .B2B-Detail {
          font-size: 14px;
        }
      }
    }

    .thirdSection {
      .cardInfo {
        padding: 2rem 1rem;
      }
      .secondCardInfo {
        padding: 2rem 1rem;
      }
    }

    .forthSection {
      .forthSectionHeading {
        font-size: 26px;
      }
      .forthSectionDetail {
        .forthSectionDetailHeading {
          font-size: 20px;
          margin-bottom: 2rem;
          width: 100%;
        }
      }
    }
  }
}

.cusImg {
  height: 20px;
}

@media screen and (max-width: 900px) {
  .cusImg {
    height: 30px;
  }
}

.VactorCus {
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.mySwiper {
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.2) !important;
}

.section-main {
  img {
    border-bottom-right-radius: 6.3rem;
    border-top-left-radius: 6.3rem;
    // height: 700px;
    height: 100%;
    width: 100%;
  }
}

.section_one,
.section_two {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 4rem 0 0;
  @media screen and (min-width: 992px) {
    padding: 0 7.4rem 0;
  }
  @media screen and (max-width: 991px) {
    padding: 0 2.4rem 0;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      margin: 4rem 0 0;
      @media screen and (max-width: 576px) {
        margin: 2rem 0 0;
        gap: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.section_one {
  padding-bottom: 2rem !important;
  @media screen and (min-width: 1100px) {
    padding-top: 4rem !important;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 2rem !important;
  }
}
@media screen and (max-width: 992px) {
  .content_text br {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .font_mbl br {
    display: none !important;
  }
}
hr {
  max-width: 1076px;
  margin: auto;
}
.brake_line {
  border: 10px solid red !important;
}
.section_two {
  padding-bottom: 0 !important;
}
.section_three {
  background-image: url("../../assets/images/bg-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 3rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 4.4rem;
  @media screen and (max-width: 1024px) {
    padding: 3rem 4.4rem;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 2rem;
      padding: 2rem 1rem;
      margin: 3rem 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    padding-top: 1rem !important;
    li {
      padding-bottom: 20px !important;
      @media screen and (max-width: 992px) {
        padding-bottom: 10px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
}

.tablet_img {
  height: 400px;
  @media screen and (max-width: 992px) {
    height: 300px;
    @media screen and (max-width: 492px) {
      height: 200px;
    }
  }
  img {
    height: 100%;
    width: 100%;
  }
}

.font_mbl {
  @media screen and (max-width: 899px) {
    font-size: 36px !important;
    @media screen and (max-width: 599px) {
      font-size: 30px !important;
    }
  }
}
.saving_text {
  @media screen and (max-width: 768px) {
    font-size: 20px !important;
    margin-top: 1rem !important;
    margin-bottom: 10px !important;
  }
}
// .content_text {
//   font-size: 20px !important;
//   font-weight: 400;
//   @media screen and (max-width: 768px) {
//     font-size: 14px !important;
//     @media screen and (max-width: 576px) {
//       font-size: 12px !important;
//     }
//   }
// }
.about_text {
  margin: 2.2rem auto !important;
  max-width: 17.8rem !important;
  @media screen and (max-width: 899px) {
    margin: 2rem auto !important;
    max-width: 14rem !important;
    font-size: 48px !important;
  }
  @media screen and (max-width: 599px) {
    font-size: 36px !important;
  }
}
@media (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 1200px !important;
  }
}
