.view-text {
  font-style: normal;
  font-weight: 400 !important;
  color: #101828;
}

.view-price {
  font-style: normal;
  font-weight: 500 !important;
  color: #16151a;
}

.summary-card.MuiCard-root {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 84px 214px -80px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}

.summary-heading {
  font-style: normal !important;
  font-weight: 500 !important;
  color: #101828;
}

.summary-cal {
  font-weight: 400 !important;
  color: #101828;
  font-style: normal !important;
}

@media(max-width:710px) {
  .view-product-box {
    padding-left: 15px;
  }
}

@media(max-width:595px) {
  .view-product-box {
    padding-left: 0px;
  }
}