@import "../../../scss/components/card";
@import "../../../scss//variables";

.carousel-container {
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
}

.swiper-button-next:after {
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  border-radius: 17px;
  background-color: #fff;
  font-size: 15px !important;
  font-weight: bold !important;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev:after {
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  border-radius: 17px;
  background-color: #fff;
  font-size: 15px !important;
  font-weight: bold !important;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-card.MuiCard-root {
  background: transparent;
  cursor: pointer;
  border-radius: 0 !important;
  box-shadow: none;
  border: 1px solid #efefef;
  padding: 15px 15px;
}

.carousel-img-container {
  img {
    height: 150px;
    width: 150px;
    object-fit: cover;
  }
}

.carousel-img-container-category {
  img {
    height: 220px;
    width: 100%;
    padding: 0;
    object-fit: cover;
  }
}

.cusProductName {
  background: #f3f5f7;
  text-align: center;
  border-radius: 4px;
  width: 100px;
  padding: 10px;
  align-self: start;

  p {
    font-size: 10px;
    font-weight: 500;
    font-family: cursive !important;
  }
}

.cusCardProductName {
  text-align: center;
  min-height: 150px;
  max-height: 150px;
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 500;
  }
}

.category-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #101828;
}

.main-text {
  font-weight: 500 !important;
  font-size: 40px !important;
  color: #000000;
}

.upto-text {
  font-weight: 500 !important;
  font-size: 36px !important;
  color: #000000;
}

.latest-product-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $ebony-color !important;
  line-height: 20px !important;
}

.latest-product-subtext {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #282f3e !important;
  white-space: nowrap;
}

.price {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #1c1c1c;
}

.main-heading-landing-page {
  color: "#000000" !important;
  font-weight: 700 !important;
  font-size: 36px !important;
}

.banner-img {
  width: 100%;
}

.explore {
  text-align: center;
}

@media screen and (max-width: 1050px) {
  .main-text {
    font-size: 54px !important;
  }

  .upto-text {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 900px) {
  .main-text {
    font-size: 44px !important;
    text-align: center;
  }

  .upto-text {
    font-size: 24px !important;
  }

  .banner-img {
    width: 300px;
  }

  .main-heading-landing-page {
    font-size: 25px !important;
  }

  .category-text {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 600px) {
  .main-heading-landing-page {
    font-size: 20px !important;
  }

  .category-text {
    font-size: 12px !important;
  }

  .cusProductName {
    p {
      font-size: 8px;
    }
  }

  .main-text {
    font-size: 35px !important;
    text-align: center;
  }

  .upto-text {
    font-size: 20px !important;
  }

  .banner-img {
    width: 200px;
  }
}
