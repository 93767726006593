.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.pricingCardMain{
    display: flex;
    margin: 80px 0 60px;
    justify-content: center;
    @media screen and (max-width: 599px) {
        flex-direction: column;
        gap: 20px;
        margin:30px 0 10px;
    }
    .pricingCard{
        flex: 1 1;
        max-width: 290px;
        background-color: #ffffff;
        border: 1px solid #235d5e;
        padding: 30px 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px -2px #235d5e;
        text-align: center;
        @media screen and (max-width: 899px)  {
            padding: 20px 7px;
         }
         @media screen and (max-width: 599px)  {
            width: 290px;
            margin: 0 auto;
            padding: 20px 10px !important;
         }
        &:nth-child(1){
            padding: 30px 35px 30px 20px;
            @media screen and (max-width: 899px)  {
                padding: 20px 20px 20px 10px;
             }
        }
        &:nth-child(3){
            padding: 30px 20px 30px 35px ;
            @media screen and (max-width: 899px)  {
                padding: 20px 10px 20px 20px;
             }
        }
        &:nth-child(2){
            transform: scale(1.1);
            z-index: 9;
       
            @media screen and (max-width: 599px) {
                transform: scale(1);
            }
        }
        .imageIcon{
            margin-top: 20px;
            @media screen and (max-width: 899px)  {
                margin-top: 10px;
             }
        }
        .heading{
            font-size: 26px;
            font-weight: 600;
            @media screen and (max-width: 899px)  {
                font-size: 22px;
            }
        }
        .title{
            font-size: 16px;
            max-width: 160px;
            margin: 0 auto;
            @media screen and (max-width: 899px)  {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .price{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 20px;
            @media screen and (max-width: 899px)  {
                margin-top: 10px;
            }
            .sign{
                font-size: 28px;
                @media screen and (max-width: 899px)  {
                    font-size: 22px;
                }
            }
            .digit{
                font-size: 65px;
                line-height: 65px;
                margin-bottom: 20px;
                font-weight: 600;
                @media screen and (max-width: 899px)  {
                    font-size: 45px;
                    line-height: 45px;
                    margin-bottom: 15px;
                }
            }
        }
        .cardBtn{
            text-align: center;
            button{
                border-radius: 40px;
                width: 100%;
                background-color: #235d5e !important;
                color: white;
                padding: 12px;
                @media screen and (max-width: 899px)  {
                    padding: 6px;
                    font-size: 13px !important;
                }
            }
        }
    }
}
