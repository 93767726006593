@import "../variables";

.createProductSetpper {
  .MuiStepIcon-root.Mui-active {
    color: #f4f7f7 !important;
    border: 1px solid #235d5e !important;
    border-radius: 12px !important;
  }

  .MuiStepIcon-text {
    fill: #235d5e !important;
    font-size: 18px !important;
    font-weight: 700;
  }

  .MuiStepIcon-root.Mui-completed {
    color: #235d5e !important;
  }

  .MuiSvgIcon-root {
    color: #f4f7f7 !important;
    border-radius: 12px !important;
  }
}

@media (max-width: 768px) {
  .createProductSetpper {
    .MuiStepIcon-text {
      fill: #235d5e !important;
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}
