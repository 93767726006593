@import "../../../../scss/variables";

.signature-pad-container {
  min-width: 600px;

  .signature-pad-header {
    background-color: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    padding: 0.6rem;
  }

  .signature-pad-body {
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .signature-clear {
      position: absolute;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      font-size: 30px;
      background-color: #fff;
    }

    .signaturePad {
      width: 100%;
      height: 300px;
    }
  }

  .signature-pad-footer {
    background-color: $primary-color;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    border-radius: 0 0 8px 8px;
    padding: 0.6rem;
  }

  button:disabled {
    background-color: #a6a2a2e6 !important;
    color: #333333b3 !important;
  }
}