.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.carousel-img-container-category {
    img {
      height: 220px;
      width: 100%;
      padding: 0;
      object-fit: cover;
    }
  }
  .category-card.MuiCard-root {
    background: transparent;
    cursor: pointer;
    border-radius: 0 !important;
    box-shadow: none;
    border: 1px solid #efefef;
    padding: 15px 15px;
  }