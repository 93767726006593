@import "../../../../scss/variables";

header.page-header {
  box-shadow: none;

  @include themify($themes) {
    background: themed("barBg");
  }

  .navMenuItems {
    @include themify($themes) {
      color: themed("txtColor");
    }
  }

  .activeLink {
    border-bottom: 1px solid $ebony-color;
    font-weight: 700;
    color: $ebony-color;
  }

  color: #9fa3a9;
  font-size: 14px;

  .MuiSvgIcon-root {
    @include themify($themes) {
      fill: themed("txtColor");
    }
  }
}

.theme-light {
  .theme-change-icon {
    background-image: url("../../../assets/images/bytesize_moon.svg");
  }
}

.theme-dark {
  .theme-change-icon {
    background-image: url("../../../assets/images/eva_sun-fill.svg");
  }
}

.notification-icon {
  background-image: url("../../../assets/images/codicon_bell.svg");
}

.user-icon {
  background-image: url("../../../assets/images/carbon_user-avatar-filled-alt.svg");
}

.nxusLogo {
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.header-search {
  width: 100%;
  height: 36px !important;
  margin: 0px !important;
  place-content: center;

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 8px !important;
  }
}

.notification-menu {
  .MuiPaper-root {
    width: 400px;
    border-radius: 10px;
    overflow: hidden;

    .notification-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px;

      .notification-label {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #101828;
      }
    }
  }
}

.cart-menu {
  .MuiPaper-root {
    width: 450px;
    height: 400px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.06);
    border-radius: 10px;

    .MuiList-root {
      padding-top: 0;
    }

    .cart-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cart-label {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #101828;
      }
    }

    .cart-body {
      height: 270px;
      overflow: auto;
    }

    .cart-footer {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

.pharmacies-select {
  width: auto !important;
  height: 36px !important;
  background: #ffffff;
  border-radius: 8px !important;

  .MuiSelect-icon {
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    padding-right: 24px;
    background-image: url("../../../assets/images/arrowDown.svg");
  }
}

.pharmacies-select:hover fieldset {
  border: 1px solid #e7e8ea !important;
  background: transparent;
  border-radius: 8px !important;
}

.cusSelect-container {
  .MuiSelect-select {
    padding: 4px 14px;
  }
}

.endAdornment-SearchIcon {
  display: none;
}

.createProduct .MuiButton-root {
  padding: " 7px 8px";
  background-color: "#7366FF";
  border-radius: "6px";
  color: "#FFFF";
  text-transform: "capitalize";
  font-size: "14px";
  font-weight: 400;
}

.createProduct:hover {
  background-color: "#7366FF";
}

.headerLogoContainer {
  display: flex;
  cursor: pointer;

  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}

.in-nav-options {
  display: flex;
}

.out-nav-options {
  display: none;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #dbdbdb;
}

.out-nav-options1 {
  display: none;
  justify-content: center;
  width: 100%;
}

.logo-size {
  width: 26px;
}

.header-logo-text {
  margin-left: 7px !important;
  font-weight: 700 !important;
  font-size: 25.7758px !important;
  color: #325c5d !important;
}

.header-logo {
  display: flex;
  cursor: pointer;
}

.badgeStyle .MuiBadge-badge {
  font-size: 14px;
  font-weight: 600;
  background-color: #f04438;
  color: white;
  z-index: 1;
}

.in-nav-options1 {
  display: flex;
}

.out-nav-options1 {
  display: none;
}

.toastr1 {
  background-color: #d32f2f;
  color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.toastr2 {
  background-color: #edc202;
  color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.bottom-right-messages {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999; /* Set a higher z-index value to make the messages appear on top */
}

.toastr3 {
  background-color: #d32f2f;
  color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
}

.toastr4 {
  background-color: #2fd337;
  color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
}
@media (max-width: 1112px) {
  .in-nav-options1 {
    display: none !important;
  }

  .out-nav-options1 {
    display: flex !important;
  }
}

@media (max-width: 660px) {
  .out-nav-options {
    display: flex;
  }

  .in-nav-options {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 600px) {
  .headerLogoContainer {
    .logoText {
      font-size: 21.7758px;
    }
  }
}

@media (max-width: 420px) {
  .logo-size {
    width: 20px !important;
  }

  .header-logo-text {
    margin-left: 5px !important;
    font-size: 16px !important;
  }
}
