.mainParagraph{
    padding-bottom: 40px;
    @media screen and (max-width: 599px) {
        padding:10px 15px 25px
    }
}
.testimonialCardMain{
    display: flex;
    flex-wrap: wrap;
    background-image: url("../../../assets/businessPage/grey-bg.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 0 0 10px 10px;
    padding: 50px 25px;
    background-repeat: no-repeat;
    gap: 20px;
    @media screen and (max-width: 599px) {
        flex-direction: column;
        gap: 20px;
        margin:30px 0 10px;
    }
    .testimonialCard{
        flex-basis: calc(33% - 11px);
        background-color: #ffffff;
        padding: 100px 20px 30px;
        border-radius: 10px;
        box-shadow: 0 0 10px -2px #235d5e;
        text-align: center;
        position: relative;
        margin-top: 75px;
        @media screen and (max-width: 899px)  {
            padding: 100px 7px 20px;
            flex-basis: calc(50% - 10px);
         }
         @media screen and (max-width: 599px)  {
            flex-basis: calc(100%);
            padding: 100px 10px 20px !important;
         }
        .imageIcon{
                position: absolute;
                width: 150px;
                height: 150px;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                z-index: 9;
                border: 2px solid #235d5e8a;
                border-bottom: 0;
                object-fit: cover;
                background-color: #235d5e;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                padding: 10px;
                .pharmacyName{
                    font-size: 16px;
                    font-weight: 600;
                }
        }
        .heading{
            font-size: 26px;
            font-weight: 600;
            @media screen and (max-width: 899px)  {
                font-size: 22px;
            }
        }
        .title{
            font-size: 16px;
            margin: 0 auto;
            @media screen and (max-width: 899px)  {
                font-size: 14px;
                line-height: 18px;
            }
        }
       .rating{
        svg{
            color: #ffa500;
            fill: #ffa500;
        }
       }
       .quote{
        color: #235d5e;
        font-size: 100px;
        line-height: 0px;
        transform: rotate(180deg);
        letter-spacing: -8px;
        margin-top: 72px;
       }
    }
}
